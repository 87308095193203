.techs {
    padding: 100px 70px;
    background-color: #272727;
    color: #fff;
}

.techs__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.techs__title {
    margin: 0;
    padding: 90px 0 26px;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -0.04em;
}

.techs__description {
    max-width: 460px;
    margin: 0;
    padding-bottom: 100px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.04em;
}

.techs__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: space-between;
}

.techs__list-element {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    min-height: 60px;
    background: #303030;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.04em;
}

@media screen and (min-width: 768px) and (max-width: 1024px){

    .techs {
        padding: 90px 50px;
    }

    .techs__title {
        padding: 80px 0 22px;
    }

    .techs__description {
        padding-bottom: 83px;
        font-size: 12px;
        line-height: 18px;
    }

    .techs__list-element {
        min-width: 84px;
        min-height: 57px;
        font-size: 12px;
        line-height: 15px;
    }
}

@media screen and (max-width: 767px) {

    .techs {
        padding: 70px 18px;
    }

    .techs__title {
        padding: 60px 0 24px;
        font-size: 30px;
        line-height: 36px;
    }

    .techs__description {
        padding-bottom: 49px;
        font-size: 11px;
        line-height: 16px;
    }

    .techs__list {
        padding: 0 52px;
    }

    .techs__list-element {
        min-width: 84px;
        min-height: 57px;
        font-size: 12px;
        line-height: 15px;
    }
}