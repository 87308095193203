.movie {
    box-sizing: border-box;
    width: 360px;
    height: 271px;
    margin: 0 0 18px 0;
    padding: 0;
    background: #202020;
}

.movie__card {
    position: relative;
    width: 100%;
}

.movie__picture {
    object-fit: cover;
    object-position: center;
    display: block;
    overflow: clip;
    width: 100%;
    height: 203px;
    border-radius: 10px;
}

.movie__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 4px 14px 0 14px;
}

.movie__title {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.movie__duration {
    min-width: 52px;
    padding: 3px 4px;
    background-color: #2F2F2F;
    background-size: cover;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    color: #8B8B8B;
    text-align: center;
}

.movie__like_active {
    position: absolute;
    top: 11%;
    left: 92%;
    transform: translate(-50%, -50%);
    padding: 10px 10px;
    background-image: url(../../../images/liked_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: none;
    border-radius: 50px;
}

.movie__remove {
    position: absolute;
    top: 11%;
    left: 92%;
    visibility: hidden;
    transform: translate(-50%, -50%);
    padding: 10px 10px;
    background-color: #F9F9F9;
    background-image: url(../../../images/delete_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: none;
    border-radius: 50px;
}

.movie__like {
    -ms-transform: translate(-50%, -50%);
    position: absolute;
    top: 11%;
    left: 86%;
    visibility: hidden;
    transform: translate(-50%, -50%);
    width: 72px;
    height: 21px;
    padding: 6px 7px;
    background-color: #313131;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    font-size: 10px;
    font-weight: 400;
    line-height: 9px;
    text-align: center;
}

.movie__like::before {
    content: "Сохранить";
    color: #fff;
}

.movie__card:hover .movie__like {
    visibility: visible;
    transition: visibility .3s;
}

.movie__card:hover .movie__remove {
    visibility: visible;
    transition: visibility .3s;
}

@media screen and (max-width: 820px) {

    .movie {
        width: 339px;
        margin-bottom: 9px;
    }

    .movie__header {
        padding: 3px 0px 6px 0px;
    }

    .movie__title {
        font-size: 13px;
        line-height: 16px;
    }

    .movie__duration {
        min-width: 52px;
        padding: 3px 0px;
    }
}

@media screen and (max-width: 550px) {

    .movie {
        max-height: 236px;
    }

    .movie__like {
        visibility: visible;
    }

    .movie__remove {
        visibility: visible;
    }

    .movie__picture {
        height: 168px;
    }

    .movie__header {
        padding: 7px 6px 6px 14px;
    }

    .movie__duration {
        margin-top: 0px;
        font-size: 11px;
        line-height: normal;
    }

    .movie__title {
        font-size: 12px;
        line-height: normal;
    }

    .movie__like {
        margin-right: 10px;
    }

    .movie__remove {
        margin-right: 10px;
    }
}

@media screen and (max-width: 374px) {

    .movie {
        max-width: 300px;
        min-height: 220px;
        margin-bottom: 3px;
    }

    .movie:nth-child(even) {
        margin: 0;
    }

    .movie__header {
        padding: 16px 0;
    }

    .movie__duration {
        max-width: 52px;
        padding: 3px 0px;
        margin-bottom: 0;
    }

    .movie__like_active {
        position: absolute;
        top: 14%;
        left: 93%;
    }
    
}