.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 396px;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 70px;
}

.login__logo {
    width: 38px;
    height: 38px;
    background: url(../../images/logo_icon.svg);
    cursor: pointer;
}

.login__title {
    margin: 0;
    padding: 40px 0px;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: #fff;
}

@media screen and (max-width: 768px) {

.login {
        min-height: 1020px;
    }
}

@media screen and (max-width: 767px) {

.login {
        max-width: 260px;
        min-height: 706px;
        padding: 56px 0 30px;
    }

.login__logo {
        align-self: center;
    }

.login__title {
        padding: 50px 0 80px;
        text-align: center;
    }
}