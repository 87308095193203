body {
    background-color: #202020;
}

.app {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 1280px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Inter';
}