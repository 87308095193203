.about {
    padding: 105px 70px 110px;
    background-color: #202020;
    color: #fff;
}

.about__info-content {
    display: grid;
    gap: 40px;
    grid-template-columns: auto auto;
    margin: 0;
    padding: 70px 0 0;
    list-style: none;
}

.about__info-element {
    display: flex;
    flex-direction: column;
    gap: 26px;
    max-width: 550px;
}

.about__info-title {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.04em;
}

.about__info-description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.about__duration {
    display: grid;
    gap: 14px 0;
    grid-template-columns: 1fr 4fr;
    padding-top: 110px;
}

.about__duration-time {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    margin: 0;
    background-color: #3DDC84;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #000;
}

.about__duration-time_other {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    margin: 0;
    background-color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #fff;
}

.about__duration-description {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #8B8B8B;
    text-align: center;
}

@media screen and (max-width: 768px) {

.about {
        padding: 90px 50px 90px;
    }

.about__info-element {
        gap: 22px;
        max-width: 319px;
    }

.about__info-content {
        gap: 30px;
    }

.about__info-description {
        font-size: 12px;
        line-height: 18px;
    }

.about__duration {
        padding-top: 93px;
    }

.about__duration-time {
        font-weight: 500;
    }
}

@media screen and (max-width: 767px) {

.about {
        padding: 70px 18px 70px;
    }

.about__info-element {
        gap: 16px;
        max-width: 284px;
    }

.about__info-content {
        justify-content: center;
        gap: 56px;
        grid-template-columns: auto;
        padding: 60px 0 0;
    }

.about__info-title {
        font-size: 18px;
        line-height: 22px;
    }

.about__info-description {
        font-size: 11px;
        line-height: 16px;
    }

.about__duration {
        gap: 10px 0;
        grid-template-columns: minmax(100px, 1fr) 4fr;
        padding-top: 60px;
    }

.about__duration-time {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
    }

.about__duration-description {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
    }
}