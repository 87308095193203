.section-title {
    margin: 0;
    padding: 0;
    padding-bottom: 23px;
    border-bottom: 1px solid #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.04em;
}

@media screen and (max-width: 767px) {

.section-title {
        padding-bottom: 28px;
        font-size: 18px;
        line-height: 22px;
    }
}