@keyframes preloader-inside-white {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes preloader-inside-red {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
30% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.preloader__container {
    position: relative;
    width: 100px;
    height: 100px;
}

.preloader__round {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background: #4285F4;
    border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.preloader__round::after {
    animation: preloader-inside-white 1s ease-in-out infinite;
    background: white;
}

.preloader__round::before {
    z-index: 10;
    animation: preloader-inside-red 1s ease-in-out infinite;
    background: #4285F4;
}
