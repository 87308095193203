

.search-form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding-top: 62px;
    padding-bottom: 25px;
    border-bottom: 1px solid #424242;
}

.search-form__all {
    display: flex;
    align-items: center;
    width: 100%;
    height: 72px;
    border-radius: 8px;
}

.search-form__input {
    box-sizing: border-box;
    width: 100%;
    margin-right: 6px;
    padding: 16px 19px 16px 16px;
    background-color: #2F2F2F;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #A0A0A0;
}

.search-form__input:focus {
    outline: 1px solid #E8E8E8;
    border-radius: 9px;
}

.search-form__button {
    cursor: pointer;
    box-sizing: border-box;
    min-width: 44px;
    height: 44px;
    padding: 0;
    background-color: transparent;
    background-image: url(../../../images/search_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.search-form__filter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
    position: relative;
    padding: 10px 40px 5px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000;
}

.search-form__filter-name {
    color: #fff;
}

.search-form__error {
    color: #EE3465;
    text-align: center;
    font-size: 14px;
}

.search-form__tumbler {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.search-form__tumbler-visible {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    transform: rotate(180deg);
    box-sizing: content-box;
    width: 34px;
    height: 14px;
    margin-left: 4%;
    transition: 250ms;
    background-color: #EBEBEB;
    cursor: pointer;
    border-radius: 20px;
}

.search-form__tumbler-visible::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translate(200%, -50%);
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    transition: inherit;
    background-color: #fff;
    border-radius: 50%;
}

.search-form__tumbler:checked+.search-form__tumbler-visible {
    background-color: #2be080;
}

.search-form__tumbler:checked+.search-form__tumbler-visible::before {
    transform: translate(0, -50%);
}



@media screen and (max-width: 1023px) {

.search-form {
        padding-top: 80px;
        padding-bottom: 110px;
    }

.search-form__icon {
        margin-left: 27px;
    }

.search-form__filter {
        padding: 16px 25px 16px 9px;
    }

.search-form__input {
        padding: 16px 50px 16px 19px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}

@media screen and (max-width: 768px) {

.search-form {
        padding-top: 70px;
        padding-bottom: 10px;
    }

.search-form__filter {
        padding: 15px 25px 16px 9px;
        font-size: 13px;
    }
}

@media screen and (max-width: 320px) {

.search-form__tumbler-visible {
        margin-left: 0px;
    }

.search-form {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-top: 67px;
        padding-bottom: 90px;
    }

.search-form__filter {
        position: absolute;
        top: 160px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
    }

.search-form__filter-name {
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}