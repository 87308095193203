.navtab__content {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 0 0 35px 0;
    padding: 0;
    list-style: none;
}

.navtab__link {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 96px;
    min-height: 36px;
    padding: 0;
    transition: all 0.3s ease-in-out;
    background: #303030;
    cursor: pointer;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
    text-align: center;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .navtab__link {
        min-width: 82px;
        min-height: 26px;
        padding: 0;
        margin: 0;
        font-size: 10px;
    }
    .navtab__content {
        margin: 0 0 50px 0;
    }
}