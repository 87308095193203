.auth-navigation-buttons {
    margin-left: auto;
}

.auth-navigation-buttons__content {
    display: flex;
    gap: 30px;
    margin: 0 0 7px 0;
    padding: 0;
    list-style: none;
}

.auth-navigation-buttons__link {
    transition: all 0.2s linear;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
    text-align: center;
    text-decoration: none;
}

.auth-navigation-buttons__link_login {
    padding: 8px 20px;
    background: #3DDC84;
    border-radius: 3px;
    color: #000;
}

@media screen and (max-width: 767px) {

.auth-navigation-buttons__link {
        font-size: 10px;
    }

.auth-navigation-buttons__content {
        gap: 14px;
    }

.auth-navigation-buttons__link_login {
        padding: 5px 12px;
    }
}