.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form__error {
    min-height: 13px;
    margin: 0;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    color: #EE3465;
}

.form__submit {
    position: relative;
    min-width: 396px;
    height: 45px;
    margin-top: 157px;
    background-color: #4285F4;
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #FFF;
    text-align: center;
}

.form__submit_disabled {
    cursor: not-allowed;
    background-color: #8B8B8B;
}

.form__submit_login {
    margin-top: 69px;
}

.form__link-caption {
    margin: 0;
    padding-top: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #8B8B8B;
    text-align: center;
}

.form__link {
    padding-left: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #4285F4;
    text-align: center;
    text-decoration: none;
}

@media screen and (max-width: 767px) {

.form__error {
      margin: 180px 0 20px;
  }

.form__submit {
      min-width: 260px;
      font-size: 12px;
      line-height: 15px;
  }

.form__link {
      font-size: 12px;
      line-height: 15px;
  }

.form__link-caption {
      font-size: 12px;
      line-height: 15px;
  }
}