.register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 396px;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 70px;
}

.register__logo {
    width: 38px;
    height: 38px;
    background: url(../../images/logo_icon.svg);
    cursor: pointer;
}

.register__title {
    margin: 0;
    padding: 40px 0px;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: #fff;
}