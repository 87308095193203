

.movies-card-list {
    display: flex;
    flex-direction: column;
}

.movies-card-list__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 80px;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
}

.movies-card-list__expand {
    cursor: pointer;
    align-self: center;
    box-sizing: border-box;
    width: 320px;
    height: 36px;
    margin: 30px 0 81px;
    background: #202020;
    border: 1px solid #424242;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #fff;
}

.movies-card-list__nothing {
    color: #fff;
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {

.movies-card-list__container {
        justify-content: space-around;
    }
}

@media screen and (max-width: 400px) {

.movies-card-list__container {
        margin-top: 8px;
        padding-top: 40px;
    }

.movies-card-list__expand {
        max-width: 240px;
    }
}