@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url(./Inter/Inter-Regular.woff2) format("woff2"),
    url(./Inter/Inter-Regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src:
    local("Inter Medium"),
    url(./Inter/Inter-Medium.woff2) format("woff2"),
    url(./Inter/Inter-Medium.woff) format("woff");
  font-weight: 500;
}
