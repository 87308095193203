.aboutme {
    padding: 110px 70px 100px;
    background-color: #202020;
    color: #fff;
}

.aboutme__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 66px;
}

.aboutme__info {
    display: flex;
    flex-direction: column;
    max-width: 599px;
}

.aboutme__name {
    margin: 0;
    padding-bottom: 18px;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -0.04em;
}


.aboutme__position {
    margin: 0;
    padding-bottom: 26px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.aboutme__description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.aboutme__link {
    padding-top: 100px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    text-decoration: none;
}

.aboutme__photo {
    width: 270px;
    height: 327px;
    border-radius: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

.aboutme {
        padding: 90px 50px 90px;
    }

.aboutme__name {
        padding-bottom: 16px;
        font-size: 40px;
        line-height: 40px;
    }

.aboutme__position {
        padding-bottom: 20px;
        font-size: 12px;
        line-height: 18px;
    }

.aboutme__description {
        max-width: 364px;
        font-size: 12px;
        line-height: 18px;
    }

.aboutme__photo {
        width: 255px;
        height: 307px;
    }

.aboutme__link {
        padding-top: 87px;
    }
}

@media screen and (max-width: 767px) {

.aboutme {
        padding: 70px 14px 70px;
    }

.aboutme__content {
        flex-direction: column-reverse;
        padding-top: 60px;
    }

.aboutme__photo {
        width: 292px;
        height: 352px;
        margin: 0 auto;
    }

.aboutme__name {
        padding-top: 40px;
        font-size: 30px;
        line-height: 36px;
    }

.aboutme__position {
        padding-bottom: 20px;
        font-size: 11px;
        line-height: 16px;
    }

.aboutme__description {
        font-size: 11px;
        line-height: 16px;
    }

.aboutme__link {
        padding-top: 42px;
    }
}