.footer {
    padding: 78px 70px 20px;
    background-color: #202020;
}

.footer__title {
    margin: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #8B8B8B;
    text-align: center;
}

.footer__content {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.footer__copyright {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
}

.footer__links {
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer__link {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
    text-decoration: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .footer {
        padding: 156px 30px 20px;
    }
}

@media screen and (max-width: 320px) {
    .footer {
        padding: 77px 10px 20px;
    }
    
    .footer__title {
        font-size: 12px;
        line-height: 15px;
    }
    
    .footer__content {
        flex-direction: column-reverse;
        gap: 30px;
    }
    
    .footer__links {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding-top: 8px;
    }
    
    .footer__link {
        font-size: 12px;
        line-height: 15px;
    }
    
    .footer__copyright {
        font-size: 12px;
        line-height: 15px;
        color: #8B8B8B;
        text-align: center;
    
    }
}

