.portfolio {
    padding: 0px 70px 113px;
    background: #202020;
}

.portfolio__title {
    margin: 0;
    color: #8B8B8B;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}

.portfolio__list {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    list-style: none;
}

.portfolio__item {
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: -0.04em;
    padding: 20px 0 10px;
    border-bottom: 1px solid #fff;
}

.portfolio__item:last-child {
    padding: 20px 0 0;
    border-bottom: 0;
}

.portfolio__icon {
    font-weight: 400;
    font-size: 30px;
    line-height: 60px;
    letter-spacing: -0.04em;
}

.portfolio__link {
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .portfolio {
        padding: 0 50px;
    }

    .portfolio__link {
        font-size: 28px;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: -0.04em;
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .portfolio {
        padding: 0 50px;
    }

    .portfolio__title {}

    .portfolio__list {
        padding-top: 10px;
    }

    .portfolio__item {
        font-size: 18px;
        line-height: 28px;
        padding: 30px 0 15px;
    }

    .portfolio__icon {
        font-size: 18px;
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {
    .portfolio {
        padding: 0 14px;
        margin-bottom: 70px;
    }

    .portfolio__title {
        font-size: 14px;
    }

    .portfolio__list {
        padding-top: 10px;
    }

    .portfolio__item {
        font-size: 18px;
        line-height: 28px;
        padding: 30px 0 15px;
    }

    .portfolio__icon {
        font-size: 18px;
        line-height: 28px;
    }
}